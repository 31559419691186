.header{
    width: 100%;
    background-color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;  
}
.headerContent{
    display: flex;
    vertical-align: center;
    width: 800px;
    max-width: 100%;
}
.headerSubtitleContainer{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--green);
    box-shadow: 0 0 8px 4px rgb(0 0 0 / 0.2);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;  
}
.headerSubtitleContainerUnder{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--yellow);
    box-shadow: 0 0 8px 4px rgb(0 0 0 / 0.2);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;  
}
.headerSubtitle{
    display: flex;
    justify-content: center;
   
    width: 800px;
    max-width: 100%;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}
.logo{
  
}
.downloadContainer{
    background-color: black;
    width: 100%;
    padding-top: 10px;
   
    display: flex;
    justify-content: center;  
    color: white;
}
.download{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 800px;
    max-width: 100%;
    
    font-weight: bold;
}
img{
    max-width: 100%;
    
}
.appImageContainer{
    display: flex;
   justify-content: center;
   
}

.appImageContainer img{
    display: block; 
   width: 512px;
}