.infoWrap{
   background-color: black;
}
.navigationHeader {
    background-color: var(--black);
   
  }
  
  .appbar {
    background-color: var(--black);
  }
  
  .logo {
    width: 128px;
    height: 50px;
  }
  
  .container {
    display: flex;
    flex-direction: row;
  }
  
  .savedValues {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .backLayer {
    height: 100%;
    background-color: #2b2b2e; /* Consider replacing with a CSS variable */
  }
  
  .navigationHeader {
    background-color: var(--grayDarker);
   
  }
  
  .appbarText {
    color: var(--white);
    flex: 1;
    font-size: 14px;
    align-self: flex-end;
  }
  
  .appbarKommun {
    font-size: 14px;
  }
  
  .appbarPrice {
    font-size: 14px;
  }
  
  .splashContainer {
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
  .splashAnim {
    width: 256px;
    height: 256px;
  }
  
  .info {
    color: var(--white);
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
    padding: 10px;
  }
  
  .infoContainer {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display:flex;
    justify-content: center;
}

.my-masonry-grid {
  display: flex;
  padding: 10px;
  background-color: var(--grayDarker);
  border-radius: 10px;
  width: 800px;
  margin-top: 20px;
}

.my-masonry-grid_column {
  padding-left: 2px; 
  padding-right: 2px;
  background-clip: padding-box;
}

.infoBlock {
 
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--grayBright);
  padding: 10px;
  border-radius: 10px;
  margin-top:5px;
}

.infoHeadline {
  background-color: var(--yellow);
  padding: 10px;
  border-radius: 5px;
  
  color: var(--grayDarker);
  cursor: pointer;
}

.infoHeadlineText {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.textBlockContainer {
  background-color: var(--green);
  padding: 10px;
  color: var(--grayDarker);
  border-radius: 10px;
  margin-top: 10px;
}

  
  .infoEnd {
    background-color: var(--yellow);
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .infoEndText {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
  
  .pusher {
    min-height: 100px;
  }

 
  
  /* Define your color variables here */
  :root {
    --black: #000; /* Replace with your actual color values */
    --grayDarker: #202021; /* Replace with your actual color values */
    --white: #FFF; /* Replace with your actual color values */
    --grayBright: #3e3e42; /* Replace with your actual color values */
    --yellow: #f1be18; /* Replace with your actual color values */
    --green: #21d27b; /* Replace with your actual color values */
    --pink: #f920db; /* Replace with your actual color values */
  }
  